import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesScar: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Scarlet: Black Shadow guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_scarlet.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Scarlet: Black Shadow guide & review</h1>
          <h2>
            A guide & review for Scarlet: Black Shadow in NIKKE: Goddess of
            Victory.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="scarlet-black-shadow"
            enablePopover
          />
        </div>
        <p>
          A legendary warrior of the past, one of the few living remnants
          weathering the indiscriminate tribulation in the ancient times,
          Scarlet has emerged as her once ruthless form—Scarlet: Black Shadow.
          Her current appearance is a tad deceptive; who would expect a drunken
          girl was once a fighter who knew no forgiveness? Whispers of her now
          inanimate comrades rang in her head as she stepped into a world
          disconcertingly familiar.
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Scarlet: Black Shadow (SBS) is an elegant swordmaster who launches
          well-timed attacks to unleash multiple strikes in quick succession,
          each dealing significant damage to one or more enemies. Every strike
          is identified by a different phase, and in each phase the nature of
          this offensive ability would be distinct. Do note that these strikes
          <strong>cannot core-hit</strong> but can <strong>crit</strong>.
        </p>
        <p>
          While her insidious attacks necessitate an unavoidable tiny delay at
          the end of each hit (0.23s), her fire rate can be compared to the
          default attack speed of Shotguns (SG), estimated to be around 0.73s
          per shot. This also means that she is <strong>auto-friendly</strong>,
          yay! It is, however, possible to shorten the interval between each
          attack by providing Charge Speed buffs, whether through OL or by
          pairing with other units (especially Alice), effectively accelerating
          the cycle. Unfortunately, this also comes with a drawback: her basic
          attack virtually has no range (500 for default RL radius; 50 for SBS).
        </p>
        <p>
          Before we dive deep into her intricacies, let's scratch the surface
          first:
        </p>
        <h6>Skill 1</h6>
        <blockquote>
          <p>
            ■ Activates on Full charge. Effects change according to number of
            attacks. Each phase does not stack.{' '}
          </p>
          <p>Three attacks: Affects the enemy with the lowest defense.</p>
          <p>Deals 250.47% of final ATK as additional damage.</p>
          <p>Six attacks: Affects all enemies within attack range.</p>
          <p>Deals 500% of final ATK as distributed damage.</p>
          <p>Nine attacks: Affects all enemies.</p>
          <p>Deals 750.47% of final ATK as distributed damage.</p>
        </blockquote>
        <p>
          This part of SBS is technically not an actual component of her
          skillset but rather constitutes her basic mechanics. It sets the
          baseline DPS output of SBS and her skill multipliers. Basically, this
          is where the phases of SBS are described. In short, every 3 attacks
          when not in her Burst Skill, SBS triggers a phase change and activates
          the effect of that new phase, whether it be dealing additional damage
          or poking all enemies with distributed damage. During Burst Skill, she
          can trigger this every 1 attack instead of 3, allowing her to
          repeatedly activate the phase change and dealing bonus damage.
        </p>
        <Alert variant="primary">
          <p>
            Because some of her attacks contain Distributed Damage, SBS might
            seemingly display less performance on stages where there are a lot
            of mobs, but this is misleading. While that does reduce damage dealt
            to a single enemy under these circumstances, the bonus AoE will
            allow her to clean low-HP enemy mobs, which can be very annoying.
            This might prove to be extremely useful with the upcoming Material H
            boss!
          </p>
        </Alert>
        <h6>Skill 2</h6>
        <blockquote>
          <p> ■ Activates when entering Full burst. Affects Self.</p>
          <p>Max ammo Capacity increased by 60% for 10 seconds.</p>
          <p>Reload 100% of magazine.</p>
        </blockquote>
        <p>
          Successful attacks start with an ultracareful preparation. SBS
          fulfills this quote from Sun Tzu, God of War, by entitling herself to
          increased max ammo and a clean refill of her magazine at the start of
          every Full Burst. This is something that other common units have
          missed but allows SBS to rightfully stand on the podium. SBS is
          probably one of the few units that can guarantee that she{' '}
          <strong>never</strong>
          reloads in an entire run, which leads to a consistent damage output
          and a notable QoL improvement, especially due to its synergy with
          Burst Skill and Skill 1.
        </p>
        <h6>Burst</h6>
        <blockquote>
          <p>■ Affects self</p>
          <p>
            Changes Full charge attack count required for Skill to 1 time/2
            times/3 times for 10 seconds.
          </p>
          <p>ATK ▲ 115.12% for 10 sec.</p>
          <p>Charge damage ▲ 150.12% for 10 sec.</p>
        </blockquote>
        <p>
          Packed with tons of power, her Burst Skill allows SBS to triple her
          wrath. Upon activation, for the following 10 seconds, the number of
          attacks required to prompt the phase change in Skill 1 is{' '}
          <strong>reduced to one</strong>. Instead of needing 3 attacks to
          trigger each phase, she can immediately trigger the next one in one
          hit. In other words, if she activates her Burst Skill just after Phase
          2 is triggered, her next cleave will immediately trigger Phase 3 and
          so on.
        </p>
        <p>
          A significant ATK buff and a twofold Charge Damage amplification are
          also granted alongside this, turning her into a ravaging storm. This
          is where most of her DPS is located, so taking full advantage of her
          Burst Skill will allow you to appreciate her true strength. Luckily,
          unlike Alice, she is quite independent in this regard as she can
          accomplish this by ensuring a full magazine at the start of each Full
          Burst thanks to her Skill 2, which extends the cap even!
        </p>
        <Alert variant="primary">
          <p>
            In base form without any additional buffs from OL or allies, SBS can
            comfortably land 13 shots during Burst. With the help of Alice, who
            considerably lowers her charge time from 0.3s to 0.125s at max level
            due to the relative scaling of caster's Charge Speed buff (making it
            5x more effective), she can fire up to 17-18 shots. For your
            information, relative scaling of Charge Speed is dependent on the
            Charge Time of Alice and target unit. In this case, SBS's Charge
            Time is 5x shorter, which explains the 5x effectiveness. In other
            words, the 11.67% buff transforms into 58.35%.
          </p>
        </Alert>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <h6>Campaign, Tower, and Simulation:</h6>
        <p>
          SBS is not as versatile as the other B3 DPS for Campaign and Tower
          because of her inherent lack of stable AoE DPS. She does damage all
          enemies, but the more enemies there are, the less damage each of them
          takes. This is unfortunate as usually, in campaign, you want to kill
          the mobs as quickly as possible before they can even retaliate. That
          said, she is still powerful when addressing enemies in low quantities.
          This is because her Burst Skill is equipped with an insane ATK buff
          comparable to the original Scarlet.
        </p>
        <p>
          In Late Story, you would notice that she deals tons of damage,
          surpassing other DPS B3. Well, that's true, but it will get you
          nowhere with extreme high CP deficit (32%+). Why is that? Well, as you
          enter endgame content, enemies will hit very hard, which can
          potentially one-shot your units. The only way you can prevent that
          from happening is to kill them first. But, SBS is actually terrible at
          this because, while her Distributed Damage does amount to a huge sum
          (can be seen when game is paused), her attacks are killing no one in
          time as the damage is spread. This buys enough time for the enemy to
          annihilate your team prior to death. Dealing tons of damage is
          fruitless if she is not able to neutralize the threats immediately.
          Compare this to Alice or Red Hood that can quickly pick off targets
          one by one, especially the Sniper Raptures in the distance. However,
          SBS may be able to kill enemies in time against Iron enemies due to
          elemental advantage.
        </p>
        <p>
          Oh, and let's not talk about how Modernia's AoE damage isn't dependent
          on how many enemies are there in its range. The damage is fixed. This
          allows Modernia to clear a group of 5 raptures and a group of 20
          raptures in the same time. Now, is there any reason to pick SBS over
          Modernia if we only care about the overtime AoE damage? Modernia's is
          just more consistent and lasts longer too. Not only that, Modernia has
          a powerful ST damage outside Burst, while SBS's damage will fall off
          sharply after 10s.
        </p>
        <h6>Pilgrim Tower</h6>
        <p>
          Quite interestingly, she may act as a substitute in Pilgrim Tower.
          With a similar reasoning as stated previously, she may see more use
          here due to the fact that there are only a handful of options for B3,
          especially when it comes to bosses where healing is needed.
        </p>
        <h6>Bossing/Raids</h6>
        <p>
          Indispensable, irreplaceable, immovable. We can safely say that SBS
          is, if not the best, one of the most destructive DPS deployable to the
          battlefield. She provides herself with enough Max Ammo not to rely on
          anyone, enough ATK to not be useless, and a huge preference for raw
          damage multipliers in her kit. She can manage all bosses with ease,
          whether they have parts or a core, and her damage is simply reliable
          and consistent.
        </p>
        <p>
          SBS is convenient, and that is especially true when you have to deal
          with a certain boss gimmick, such as the boss spawning mobs with high
          DEF and low HP, as seen against Mother Whale and soon Solo Raid
          Material H. The Distributed Damage will finish all of them instantly
          as only the boss's DEF matters, not the mobs.
        </p>
        <h6>PVP</h6>
        <p>
          The fact that SBS's AoE damage materializes as Distributed Damage may
          be objectionable for PvP as the value would be too low if divided by
          five. Her Burst generation is also on the low side and inconsistent as
          whiffing often occurs. However, SBS has proven to offer delicacies
          that would undoubtedly outweigh these spoiled pieces of meat.
        </p>
        <p>
          She may not exhibit the highest AoE damage (that would be Scarlet) nor
          the highest ST damage (that would be Alice/Red Hood), but the pressure
          she applies while inflicting moderate ST and AoE damage at the same
          time is too impactful to ignore. Oh, don't forget that she also
          refills her magazine at the start of Full Burst, guaranteeing her
          independence. In fact, her major AoE in Phase 3 is also more deadly
          the fewer enemies there are remaining in the battle! This makes
          finishing off leftover units a frivolous task.
        </p>
        <p>
          Nonetheless, she has a direct counter — Sakura — and can be blocked
          and hard-countered by Biscuit + Defender. Since her Skill 1 has
          Distributed Damage, and if you remember from 2B, when it hits an
          immune Nikke (immunity is something Biscuit provides to defenders),
          she will do 1 damage to everyone even with the 3rd part of her Skill
          1, which cuts her damage and will most likely lead to a loss for SBS.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          SBS is finicky when it comes to gear because she already has adequate
          ammo to inflict remarkable damage, which devalues Max Ammo OL. But,
          for Quality of Life and some versatility, we suggest you get 1 Ammo
          roll on her. Now, what should I obtain for my beloved gladiator? ATK
          is never a bad line, so that's one. Elemental DMG is also useful,
          especially for the upcoming Solo Raid! so that's also a yes. Last but
          not least, Charge Speed and Charge Damage. Charge Speed is preferred
          over Charge Damage, especially in high amounts, is preferred because
          it also amplifies the damage from Skill 1 as well as enables SBS to
          potentially land one or two more shots during Burst. CRIT Rate & CRIT
          DMG are decent bonus lines for increasing SBS's overall damage,
          especially her Skill 1.
        </p>
        <p>
          There are some breakpoints for Charge Speed that you should try to aim
          for with her. These assume that SBS is paired with Alice, with Alice
          Skill 1 being level 10:
        </p>
        <ul>
          <li>0%: 17 hits during Burst</li>
          <li>
            4.9%: 18 hits during Burst (try to get this from one charge speed
            roll alone)
          </li>
          <li>
            11%: 19 hits during Burst (this will usually need 3 charge speed
            rolls, and is a lower priority unless you are aiming to perfect her)
          </li>
        </ul>
        <Alert variant="primary">
          <p>
            Ideally you would want: 4x ATK, 4x Elemental Damage, 1x Ammo and 3x
            Charge Speed, mainly aiming for ATK and Elemental damage on her.
          </p>
        </Alert>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 10</strong>
          </li>
          <ul>
            <li>
              No breakpoint because each level only contributes to more damage.
              You want to maximize this as fast as possible as it amounts to
              slightly over half of SBS's damage (more if the enemy has no
              core).
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 4/6/7</strong>
          </li>
          <ul>
            <li>
              There are several breakpoints here. First of all, the reload
              percentage reaches its peak at Level 7 at 100%. At level 4-6, this
              would be 60%. Meanwhile, the Max Ammo increases by 2.2% per level.
              Since this increase is microscopic, there appears to be no
              worthwhile return beyond Level 7 because, in base form, the actual
              Max Ammo increase remains stagnant above Level 6, earning her 5
              more Max Ammo from this skill instead of 4. To summarize:
            </li>
            <ul>
              <li>Level 4: +4 Max Ammo & 60% Reload</li>
              <li>Level 6: +5 Max Ammo & 60% Reload</li>
              <li>Level 7: +5 Max Ammo & 100% Reload</li>
            </ul>
            <li>
              Ideally, you want this to be at Level 7, so she can fit in all
              teams. However, you can get away with Level 5 or 6 instead of 7 if
              SBS if coupled with Bastion 7 + a high-level Noir S2 and/or Liter
              S1, which is most likely her best team at the moment. We also want
              to emphasize that if Skill 2 is less than 7: Noir must be placed
              to the left of SBS, so SBS's refund benefits from Noir! (You will
              have to manually use Bursts in this case.)
            </li>
            <li>
              If you are running out on resources, consider leaving this at
              Level 4 or 6 and testing it out before committing to investing
              further. If you ever see her reload outside Burst, you may choose
              to level it to 7. Level 7 will indeed guarantee a no-reload run.
              However, most of her damage is situated inside Burst, so is that
              small damage increase meaningful to you? Only you can answer.
            </li>
          </ul>
          <li>
            <strong>Burst: 10</strong>
          </li>
          <ul>
            <li>
              Likewise, any skill that contains a lot of ATK buffs and scales
              well warrants maximum investments. This is also the case for SBS.
              She deserves everything you've got because she is the epitome of
              calamity itself.
            </li>
          </ul>
        </ul>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_2_sm.png"
          alt="Guide"
        />
        <p>
          The most economical option is Bastion. While Onslaught is
          theoretically the best cube for SBS when it comes to pure damage, it
          is very niché and does not pair well with most other units. Thus, you
          will be better off using Bastion for the increased stats and elemental
          damage bonus. Besides that, when coupled with Alice, SBS will shoot
          faster. In order for her to last until the next Full Burst without
          reloading even once, you usually need to rely on Bastion (and 1 or
          more Max Ammo OL lines). This should result in more damage provided
          versus Onslaught. Regardless, you may benefit from Adjutant more if
          you have a low charge speed roll and want to reach a certain
          breakpoint (as stated in Gear Investment).
        </p>
        <p>
          Note that the case above doesn't convert well for PvP as there she is
          bound to reload before entering Full Burst if she doesn't have Max
          Ammo OL, in which case Resilience is a better option than Bastion.
          Since she gains Max Ammo from Skill 2 (and reloads fully just as Full
          Burst begins), it is unnecessary to equip Bastion to make full use of
          her devastating Burst Skill. Bastion would only be a good choice if
          Alice were also in the team, but that's usually questionable in PvP to
          bring a low-regen Nikke just to boost damage slightly.{' '}
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <p>
          Since she is the most powerful unit in the game, you should also pair
          her with the best support, which is ironically Alice. Why is that?
          Well, Alice's Skill 1 buff is actually taken from Alice's caster
          speed, which if normalized to SBS's, it would be 5x more effective!
          This translates to over 20% undiluted total damage increase.
        </p>
        <h5>Team #1 - God Bossing Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          The supreme team of 2024. SBS enjoys increased Max Ammo & refill,
          consistent damage taken, constant healing, and a strong Charge Speed
          buff from Alice. SBS can potentially fire non-stop in this team and
          should reach her ceiling.
        </p>
        <h5>Team #2 - Bunniless God Bossing Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
        </div>
        <p>
          If Bunniless, consider using Tia Naga instead. This time, however, SBS
          cannot fully enjoy Naga's buff due to the fact that her Skill 1 damage
          cannot core-hit. Thus, it wastes Tiga's supportive potential.
        </p>
        <h5>Team #3 - General Non-Bunny/Non-Tiga Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="placeholder-box">B2</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          You can use her as a replacement for the current B3s you are running
          in Campaign. If you are missing Liter, we highly recommend getting her
          through the current Guaranteed SSR Box from the on-going Step Up
          Banner. Ideally, you want to have heals so make sure either B2 or Flex
          is a healer that can help you survive. If a healer is not needed for a
          stage, you can choose an offensive support or utility like Centi for
          B2 along with Maxwell/Privaty or Noir for Flex.
        </p>
        <SectionHeader title="PVP Team Compositions" />
        <p>
          Look forward to our PvP update later this month, where we will add
          more about Quiry, Redhood, xMica and Scarlet: Black Shadow, and
          improve upon our current team examples and meta Nikke examples! Even
          so, below are several team examples you can adopt with SBS, especially
          when attacking:
        </p>
        <h5>Team #1 - God SBS Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This God SBS team can defeat the God Scarlet variant, depending on who
          has the more invested unit. If anyone has a low investment Scarlet,
          they are in trouble against SBS's wipes, which repeat every several
          seconds. This allows SBS to inflict gradual damage and outDPS
          Scarlet's damage. There are other teams with similar ideas that you
          can construct. This is a strong setup for Rookie Arena in both attack
          and defence. Point to Note: "Noise Noah Scarlet Rapunzel Jackal" is
          known as the God Comp in PvP due to it's high win-rate. There are some
          small variants you can try out which include: SBS, redhood, Alice or
          SAnis over Scarlet. Centi, Anis, or Biscuit over Rapunzel, but to
          relatively weaker effect.
        </p>
        <h5>Team #2 - Impenetrable Fortress Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
              <NikkeCharacter mode="icon" slug="nero" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This impenetrable fortress team features a tank P1 unit paired with
          Biscuit and Rapunzel, making it hard to break through. This team
          revolves around stalling and making sure SBS can output all she has
          within her 10s Burst.
        </p>
        <h5>Team #3 - More Damage Please Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team involves using RH and/or Alice to buff SBS's damage further,
          which is great for dealing with teams with extremely high durability.
          Noah and Jackal also ensure that the team last a while at the start of
          Full Burst for SBS to deal significant damage. This is possibly a
          setup for SP Arena, but be careful since it uses two out of 3 your
          major Burst Gen Units.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          If you read this guide and reach this point still with this question,
          then perhaps you should reread the guide again. Jokes aside, yes, you
          should definitely get her. In fact, if possible, also acquire a few
          copies for higher stats, but this is just a lifestyle choice more than
          a requirement. Mileage her if you have to. SBS is one of the best
          bossing units at the moment. Period. We would also recommend getting
          Modernia if your gems allow you to, as her banner is up right now as
          well, if you lack her. We won't play favorites here since we believe
          both units are a must-have for one's account, and both are worth
          Golden Mileage. If you can only go for one, we recommend flipping a
          coin!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Inflicts consistent damage against all sorts of bosses (has
                    core or not, etc).
                  </li>
                  <li>
                    Distributed damage is a great tool for addressing the
                    gimmicks of certain bosses (Mother Whale, Solo Raid Material
                    H).
                  </li>
                  <li>
                    Ranks as one of the strongest DPS in-game, especially for
                    bossing.
                  </li>
                  <li>Auto-friendly, good for boomer hands.</li>
                  <li>The only Wind DPS B3 worth investing right now.</li>
                  <li>Doesn't rely on Max Ammo OL that much.</li>
                  <li>
                    Pulling her means your friends can't mock you for being
                    Scarletless anymore.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Pilgrim, hard to get copies outside special banner.</li>
                  <li>
                    Need a lot of skill investments to be fully optimal, but the
                    breakpoint is only on Skill 2.
                  </li>
                  <li>Has some serious counters in PvP, like Sakura.</li>
                  <li>
                    DPS may be too scattered and unreliable when fighting
                    against too many enemies at once.
                  </li>
                  <li>Be careful - too much SAlt makes you unhealthy.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesScar;

export const Head: React.FC = () => (
  <Seo
    title="Scarlet: Black Shadow guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Scarlet: Black Shadow in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
